import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
  "path": "/Frequently_Asked_Question/WQHD_Shinobi_CCTV/",
  "dateChanged": "2023-06-21",
  "author": "Mike Polinowski",
  "excerpt": "Unfortunately we can't get the IN-9408 2K+ POE recognized with ONVIF, none of the programs from the wiki recognize the camera. What can we do?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Adding your INSTAR 2k+ WQHD Camera to Shinobi' dateChanged='2023-06-21' author='Mike Polinowski' tag='INSTAR IP Camera' description='Unfortunately we can`t get the IN-9408 2K+ POE recognized with ONVIF, none of the programs from the wiki recognize the camera. What can we do?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_Shinobi_CCTV/' locationFR='/fr/Frequently_Asked_Question/WQHD_Shinobi_CCTV/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "adding-your-instar-2k-wqhd-camera-to-shinobi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#adding-your-instar-2k-wqhd-camera-to-shinobi",
        "aria-label": "adding your instar 2k wqhd camera to shinobi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your INSTAR 2k+ WQHD Camera to Shinobi`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Unfortunately we can't get the IN-9408 2K+ POE recognized with onvif, none of the programs from the wiki recognize the camera. What can we do?`}</p>
    {/* Leider kriegen wir die IN-9408 2K+ POE nicht mit onvif erkannt, keins der Programme aus dem Wiki erkennt die Kamera. Was können wir tun? */}
    <p><strong parentName="p">{`A`}</strong>{`: There are `}<a parentName="p" {...{
        "href": "https://docs.shinobi.video/installation"
      }}>{`different ways to install Shinobi`}</a>{`. I choose `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose`}</code>{` and started a Shinobi container without the Tensorflow plugin and a few volume mounts to persist the configuration:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3"`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`shinobi`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` registry.gitlab.com/shinobi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`systems/shinobi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`dev
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` shinobi
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
           `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /opt/shinobi/config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/config
           `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /opt/shinobi/customAutoLoad`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/home/Shinobi/libs/customAutoLoad
           `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /opt/shinobi/database`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/var/lib/mysql
           `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /opt/shinobi/videos`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/home/Shinobi/videos
           `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /opt/shinobi/plugins`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/home/Shinobi/plugins
           `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /dev/shm/Shinobi/streams`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/dev/shm/streams
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`network_mode`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"host"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` unless`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`stopped`}</code></pre></div>
    <p>{`The command `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose up`}</code>{` will start the container and exposes the `}<strong parentName="p">{`Superuser Login Page`}</strong>{` on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8080/super`}</code>{`. Login with the default credentials and setup your installation:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mailto:admin@shinobi.video"
        }}>{`admin@shinobi.video`}</a>{` / admin`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/25c8290f484bf2f247cef042295de984/8cdda/WQHD_Shinobi_CCTV_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACgElEQVQoz03OaU/acAAG8CqoE62rbWnB0oN/6QEjoFJHNFquQrlaenAoU5BjizEOlUPdPsfeLFu2r7qwJduSJ8+735MHOn1YnE3n6myuzqeZxaO6eFSfJpnnu/znyeniJnXfI+snHnTbi8IebHsF8a3u+jzY1u/4oP/wTF08LPN8n/s0Ofo4pNoVspVHtAMvCntRGEJ8YSFIcji0u+n9izOzp8xslplPCy+z/MtUfZqk7gYBtxxo6cGOtpNN/sEeDKZAEKf9q9iWF99eYuXm7mRynxyPE6Ohcvs+dTsCly7plAKtyl5Hp7olrJxew+CVnVeryCYKKJjGIWQDQjaW+E1vJF/1Kcvas028XvKbRcIq+BtZwskTdjZ4nserCsoxQirNJvYDcgwFAi5IWERcJ3YgpmkHjCplV5lWLdQssee60LNiY1ceOFLP2mvnXmv7CEuLSjogR0kp6hckPCJivLDElFPn2ma4XQOdutA1+QtTfOcKXYc/NxhXC9oqpqd2OUZUjgkp5hejhBgjxOXEErO2KzSbVKNBWVXK0lmrzC5b45wi6xZI6wzTDhGGjqZPQ/EEGhFxQfp3mzVtxjCB64KmTZtlYFXoWp6p58J2kWtkg5ZK6grChKS3x4QUxXgBj4h/bq8RO1BIrwTLOlupROpVrq6Lhp6wy7xTAGZGNjO0laP1NMaFoqlUSJYIAAgASB4QILzuhyHeMUDbEJpGvOPsDzrJfutw1OYutFivdvTBjV9XqdIBJsrBtOZPqXBM2Yopvqjikw+8BAKBlhFuVSPdMn+hS5el6LUu9YvCVU7sa9IwJwzzrBX3JXPE4Cfe/0qPv1Ojb8ToB9H7ss6wvwDwGpaSyphxwwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/25c8290f484bf2f247cef042295de984/e4706/WQHD_Shinobi_CCTV_01.avif 230w", "/en/static/25c8290f484bf2f247cef042295de984/d1af7/WQHD_Shinobi_CCTV_01.avif 460w", "/en/static/25c8290f484bf2f247cef042295de984/7f308/WQHD_Shinobi_CCTV_01.avif 920w", "/en/static/25c8290f484bf2f247cef042295de984/16f03/WQHD_Shinobi_CCTV_01.avif 1168w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/25c8290f484bf2f247cef042295de984/a0b58/WQHD_Shinobi_CCTV_01.webp 230w", "/en/static/25c8290f484bf2f247cef042295de984/bc10c/WQHD_Shinobi_CCTV_01.webp 460w", "/en/static/25c8290f484bf2f247cef042295de984/966d8/WQHD_Shinobi_CCTV_01.webp 920w", "/en/static/25c8290f484bf2f247cef042295de984/2768a/WQHD_Shinobi_CCTV_01.webp 1168w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/25c8290f484bf2f247cef042295de984/81c8e/WQHD_Shinobi_CCTV_01.png 230w", "/en/static/25c8290f484bf2f247cef042295de984/08a84/WQHD_Shinobi_CCTV_01.png 460w", "/en/static/25c8290f484bf2f247cef042295de984/c0255/WQHD_Shinobi_CCTV_01.png 920w", "/en/static/25c8290f484bf2f247cef042295de984/8cdda/WQHD_Shinobi_CCTV_01.png 1168w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/25c8290f484bf2f247cef042295de984/c0255/WQHD_Shinobi_CCTV_01.png",
              "alt": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "title": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Under `}<strong parentName="p">{`Accounts`}</strong>{` you can create a regular user account for you to use to access the Shinobi  Dashboard that can be accessed on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8080/`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7e0021eda818c16e335bc2e88ca9454e/c45c7/WQHD_Shinobi_CCTV_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABO0lEQVQY023N3U7CMABA4SVoMiIMaLvRrl3brV03AmNjPxCDIAISh974/i9jQPRGk3P75VhDlwEsIZGOGzwAH/gqnq/UrInnKz1fEjXD0YyoDDLTQ0HfE32PD3A4wKHjcatjYwfxHmADT/Zg4Mlptm6Tej9ZHiarY5RvwnwTFVtiyj4xPWwcmjq+uQ8aKz5Zd12CqIS+AIQPPTqWSbF9zzft9PGkymdd7XS1i+s9NQUYB5BwRISLmc1Ly5wvGBLuUjH0qIOwJ0z21KbLQ3xlv5glJfRDxDSi2qVhVzSWaa07G4MgRbp0demqkqRNtn7T1e6y/SmuXq5nAUkISYiw6Ir6hkfMgLAAcj6SGTZ1sT2nq2O02Knylq72/+OOTXyufB6NqRxTwdS0OX7m24+43pvm8F2yfGXJ4i/+Arn1OlYBZ2yVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e0021eda818c16e335bc2e88ca9454e/e4706/WQHD_Shinobi_CCTV_02.avif 230w", "/en/static/7e0021eda818c16e335bc2e88ca9454e/d1af7/WQHD_Shinobi_CCTV_02.avif 460w", "/en/static/7e0021eda818c16e335bc2e88ca9454e/7f308/WQHD_Shinobi_CCTV_02.avif 920w", "/en/static/7e0021eda818c16e335bc2e88ca9454e/f68e6/WQHD_Shinobi_CCTV_02.avif 1346w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7e0021eda818c16e335bc2e88ca9454e/a0b58/WQHD_Shinobi_CCTV_02.webp 230w", "/en/static/7e0021eda818c16e335bc2e88ca9454e/bc10c/WQHD_Shinobi_CCTV_02.webp 460w", "/en/static/7e0021eda818c16e335bc2e88ca9454e/966d8/WQHD_Shinobi_CCTV_02.webp 920w", "/en/static/7e0021eda818c16e335bc2e88ca9454e/16abe/WQHD_Shinobi_CCTV_02.webp 1346w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e0021eda818c16e335bc2e88ca9454e/81c8e/WQHD_Shinobi_CCTV_02.png 230w", "/en/static/7e0021eda818c16e335bc2e88ca9454e/08a84/WQHD_Shinobi_CCTV_02.png 460w", "/en/static/7e0021eda818c16e335bc2e88ca9454e/c0255/WQHD_Shinobi_CCTV_02.png 920w", "/en/static/7e0021eda818c16e335bc2e88ca9454e/c45c7/WQHD_Shinobi_CCTV_02.png 1346w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7e0021eda818c16e335bc2e88ca9454e/c0255/WQHD_Shinobi_CCTV_02.png",
              "alt": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "title": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can add your camera by entering the `}<strong parentName="p">{`Monitors`}</strong>{` tab and select to add a new camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e9684ee9fd85f2de0c0e36e76e161c50/37048/WQHD_Shinobi_CCTV_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAwElEQVQY04XLTQ7BQBgA0LkB89P2G2aqRbUd9VM1lQZNkIoNYoNEXMDaSSRu4ZAW9iN524e2x3M70Z7KrIansmUwnotwIqPMzI0192Okbm/9+PQuL9oM3K6SwQC8iPuxGfiR3fTRcHMdVXcvPzDeqtVwHVNMmBmhtgWSgUBhOld66aopA4mpTRiYYWI7opMUlYynqJ+vktlaqtziv+wYASaMux293odZiXqTclhUQVoyEORfphbUMRPd/mKzy0/PL1mVLqwkchSDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e9684ee9fd85f2de0c0e36e76e161c50/e4706/WQHD_Shinobi_CCTV_03.avif 230w", "/en/static/e9684ee9fd85f2de0c0e36e76e161c50/d1af7/WQHD_Shinobi_CCTV_03.avif 460w", "/en/static/e9684ee9fd85f2de0c0e36e76e161c50/7f308/WQHD_Shinobi_CCTV_03.avif 920w", "/en/static/e9684ee9fd85f2de0c0e36e76e161c50/3f70d/WQHD_Shinobi_CCTV_03.avif 1352w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e9684ee9fd85f2de0c0e36e76e161c50/a0b58/WQHD_Shinobi_CCTV_03.webp 230w", "/en/static/e9684ee9fd85f2de0c0e36e76e161c50/bc10c/WQHD_Shinobi_CCTV_03.webp 460w", "/en/static/e9684ee9fd85f2de0c0e36e76e161c50/966d8/WQHD_Shinobi_CCTV_03.webp 920w", "/en/static/e9684ee9fd85f2de0c0e36e76e161c50/35b63/WQHD_Shinobi_CCTV_03.webp 1352w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e9684ee9fd85f2de0c0e36e76e161c50/81c8e/WQHD_Shinobi_CCTV_03.png 230w", "/en/static/e9684ee9fd85f2de0c0e36e76e161c50/08a84/WQHD_Shinobi_CCTV_03.png 460w", "/en/static/e9684ee9fd85f2de0c0e36e76e161c50/c0255/WQHD_Shinobi_CCTV_03.png 920w", "/en/static/e9684ee9fd85f2de0c0e36e76e161c50/37048/WQHD_Shinobi_CCTV_03.png 1352w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e9684ee9fd85f2de0c0e36e76e161c50/c0255/WQHD_Shinobi_CCTV_03.png",
              "alt": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "title": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If your camera uses the IP address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.122`}</code>{` the HTTP port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`80`}</code>{` and the user login `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin/instar`}</code>{` you could add your INSTAR Full HD camera using the `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-8015_HD/Video_Streaming/"
      }}>{`Full HD RTSP path`}</a>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rtsp://admin:instar@192.168.2.122/11`}</code>{`.`}</p>
    <p>{`For newer camera models (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2k+`}</code>{` WQHD and up) the path changed and it seems that Shinobi does not support the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h.265`}</code>{` video input provided by the `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/"
      }}>{`WQHD RTSP stream`}</a>{`. But we can use the Full HD `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`12`}</code>{` stream instead of the WQHD `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`11`}</code>{` stream:`}</p>
    {/* 
     /Innenkameras/IN-8015_HD/Video_Streaming/
     /Aussenkameras/IN-9408_WQHD/Video_Streaming/
     */}
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bd3c1ab43ff5a9d8544c081511755b5a/c9c44/WQHD_Shinobi_CCTV_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACUklEQVQoz22SyW7UMByH8wygFiYdJhNnd1Y7TuIstrPOZDqbCgK1ak+oQr1w5MA7cOQZkHhLlKlYKip9+us7+Sd9ssQPn+XVtxeH7y9trloeYqOJygUkCkwm3OSv/HFIll42W1qS4mUwYTYuz+bACmm1fkv4BrMxbbZps8V8JOIyZmMsJsFsE4tNzEfENnPdlSzMaH9EYnf2Rjf8NB+uiNiG5RqxNarWQd5ZsXCS2ia1hZmJSisWdtLArLsAUAJ+BtNWR1xe2rpHML/0aOfnvZ/3Hu0szLSo1FGlR5UWUOCnICz0qDKwkFVH0sIiaXZO1s8US/OzdLgKqhHSwUo7gzRqxAASasQB4gAJDdcaFgBxLW7kadlLQlrrIZ0pphFkaXd0ae+krRkLk9QWaR7vo4CIqWGlhiVA/LR8+dW9/xnc/5j7LYCRz3d21kM6wHwF6YRDh0nySQzS6nGjx7WRdLLmSnZ1FDdfgu3D3MIAxqQ9aogtvFzxC8UvlkH5L2pYPlk2MO+u7lC9my1tI8jY9poOV0l7SLojrvdW2ulY6HH9FDEtA1cysBivPyWrD6+XtuYRXO/DagzZJmIbv1jBrIO0c7PepT2kp5tNRdx8uNBcyYn5+v3Han8rA0eFsZOvwFT4MS/XsHgOrpNTbZOI/e1Dsb15pVhmkOF6Ny3kg5cP7olT5EpD7DenR0kta1DS4zJpD165Pl8YRpD65doktUnqU5hnKUFQgJDNFEsCUR4Wg5O253MdeIlXbuyst9P+v0jT91i6eIFGeXWnXB8u3m1/AT3MqvZpYRDUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd3c1ab43ff5a9d8544c081511755b5a/e4706/WQHD_Shinobi_CCTV_04.avif 230w", "/en/static/bd3c1ab43ff5a9d8544c081511755b5a/d1af7/WQHD_Shinobi_CCTV_04.avif 460w", "/en/static/bd3c1ab43ff5a9d8544c081511755b5a/7f308/WQHD_Shinobi_CCTV_04.avif 920w", "/en/static/bd3c1ab43ff5a9d8544c081511755b5a/b3f84/WQHD_Shinobi_CCTV_04.avif 1245w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bd3c1ab43ff5a9d8544c081511755b5a/a0b58/WQHD_Shinobi_CCTV_04.webp 230w", "/en/static/bd3c1ab43ff5a9d8544c081511755b5a/bc10c/WQHD_Shinobi_CCTV_04.webp 460w", "/en/static/bd3c1ab43ff5a9d8544c081511755b5a/966d8/WQHD_Shinobi_CCTV_04.webp 920w", "/en/static/bd3c1ab43ff5a9d8544c081511755b5a/2b169/WQHD_Shinobi_CCTV_04.webp 1245w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd3c1ab43ff5a9d8544c081511755b5a/81c8e/WQHD_Shinobi_CCTV_04.png 230w", "/en/static/bd3c1ab43ff5a9d8544c081511755b5a/08a84/WQHD_Shinobi_CCTV_04.png 460w", "/en/static/bd3c1ab43ff5a9d8544c081511755b5a/c0255/WQHD_Shinobi_CCTV_04.png 920w", "/en/static/bd3c1ab43ff5a9d8544c081511755b5a/c9c44/WQHD_Shinobi_CCTV_04.png 1245w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bd3c1ab43ff5a9d8544c081511755b5a/c0255/WQHD_Shinobi_CCTV_04.png",
              "alt": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "title": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://admin:instar@192.168.2.122/livestream/12`}</code></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/32ac3/WQHD_Shinobi_CCTV_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABWklEQVQY023OS06DQADGca5gGu1DoEBnhoEWZkqBAaalPEofqU3VhelDW6NNTIx16w28h0fwhqa4cWHyy5f/8hMW+8/a1dfZ9ruCYw1bgPI6IA1IG4g2IL1E3f9QEbsXYkuQOxFyc4UMzkUASWgPZtjPLD5ph4UVjQ2W615msPzEz83TZibLYS+tSlAAlIf5wolnlbpqUt7Pr4N4ToOi7SSUjZDdV02mEV6KNLtEIpX0qxIQWpQTXgAnrklA46m7e3I2O7q6764fyGqLb+/Q7EZnRWlshJMyRjic1hVdaBHuxDPkJnURwOUCf7xJLzt4PKDjQXl91N6fW/sN6CWgl0A3NYKi7CH0sloTnW47g6lGeE1B1BsyPtU7kap7CnJV5KnIVbEnt5lk+pLpi4ZXhid3gqoMBNWOrHDUCYqGottBki7XvXSO3KHBMuynvxQrlNvsD79pRVUZ/gCBQFRC1RiUVAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/e4706/WQHD_Shinobi_CCTV_05.avif 230w", "/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/d1af7/WQHD_Shinobi_CCTV_05.avif 460w", "/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/7f308/WQHD_Shinobi_CCTV_05.avif 920w", "/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/92269/WQHD_Shinobi_CCTV_05.avif 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/a0b58/WQHD_Shinobi_CCTV_05.webp 230w", "/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/bc10c/WQHD_Shinobi_CCTV_05.webp 460w", "/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/966d8/WQHD_Shinobi_CCTV_05.webp 920w", "/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/c7643/WQHD_Shinobi_CCTV_05.webp 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/81c8e/WQHD_Shinobi_CCTV_05.png 230w", "/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/08a84/WQHD_Shinobi_CCTV_05.png 460w", "/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/c0255/WQHD_Shinobi_CCTV_05.png 920w", "/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/32ac3/WQHD_Shinobi_CCTV_05.png 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b1cca5eb928baf8fc8690a7c7f5c57bf/c0255/WQHD_Shinobi_CCTV_05.png",
              "alt": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "title": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Probing the stream shows that it is using the correct codec and we are good to go:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/f6f7a/WQHD_Shinobi_CCTV_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAEaklEQVQ4yyWOSWxUBQCGX0gLFNqymNLZp2+ft8ybpdOZdqbTzpROpx2mpQXaaSmlQFtaKDuEzQJqFBQRFVkCJOACHpADQRKMUeNFozHRm/GgMfFgPBkP3j/TeviS//J/+YQd85fxlN5nzbYnrKk8RFJUOmwvuYiX7ngj+WQjxaSPj07l+fdWiZ9e7uZ0pZ3zlQSzgxJnR8IsjMdZ2JVivDeKIEby2NkthPMjSK1lbNlN0WlgoDVIb8TDaJfIVFnn2fk+uFbm92tbuf/iZu4tFLhyqJ3DkxYX9hjcOZFjYbodQUr0EM5XkFJl/LECbeEgg0kPvc0u+uMeZvtMjgxbfPHKJv46183P18b59f4M3324nVtn+zk3m+X6XI7bx3u5dLiMoI+8RfjoNySOf40+9Yit5RylxAa2Zf1U2jycHEnz6r6N/PDOKL+8NMCPF6b55/4M3z7fz52rO7h9YojbhzdypBJltuwgGIkc+aFdtA/sJFEY5s25Hg4WRS5NtnCg0MS7h4d4ePMsfzy7yG/Xp/n75mm+/2SUp08nuPvBJI8/Pcm9he28cTDLmb2dCKFEjvLYDKniMFqqh1J7iLk+hQuVGLN5iclClOPbsjx97wjPH5/ms7d38vjRAH9+PMtXT+Z48OVO7h7r5rXZBOf29yGoLZ30jOyha2gSM1OizfYRFuuQXPWo62tpqF9OTlrL+b39XL29iy39TcyciXBoKsTueYN9px0WDjgcu9jMWLeGoMY76BneTXFkCitTIqZ68a6tprFuOetXV6E21mB4VjFTdLg81UbaX0t71kd5WKV/0KBvUGfTiMnRi72Mbi0iaIkcfZVpOvrH0VuL5GIm2bCMLAVQ/G5ag24Crg3ocpCk0YQle2gKepGVAAHJi2lIRC2dsG2iKBJCqCVPfnCC9KYxmvObGcvGUDWDKq9OWtSZMkP4giGWNSoIGxSqXCpVLoVljSrV7hAurZkGNYZfj+DTwwh6S57Ctt1kNo0R7exnrWhTKxrIhs2VngQ3SimKLTHUcAviEjF8hoNHC9Nkx8l25ij29pLK5kllOhGUeAeZUoWuwQminWVEM4Jo2qRCOq8X43w+3clgi4NsRPGFHAJGGNmOoFmL2yHa3Eo0mcZsTuNRbQQlmsFJF4hkiojhNjY0abzgEZnvdHhQinBjexvdKYuorVDjUalulFjukv/HLVPrM1jhVql2ydS4JYRFyaLQSObxWynSjoGtS3QkLYY6bE5MZDg5FufUWJR6n7x0XOmWqfEoSzQ0WdT5dVZ5Nep9KoLotBHLFnEyPQSsFK2OgaFJ6CGZgCSjmSEqBZv5IQdZU1nnk1nllljhkljpVnCJDmsCIeoDOmv8GoIcSS8VWqku/GaS9rhJNqbSHFZRVRndUDAshbCpoOoqlqVjWzqipuBXFETVZF1AY7VPo26xMGi3YibziE56SdgSMUiGFRxTwQjJiLJEvSdIVUOAOk+Q9X4RtyRj2SGaoyEitoKmyaz1itS6m/gPWj1VbiXjQHcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/e4706/WQHD_Shinobi_CCTV_06.avif 230w", "/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/d1af7/WQHD_Shinobi_CCTV_06.avif 460w", "/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/7f308/WQHD_Shinobi_CCTV_06.avif 920w", "/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/eae7e/WQHD_Shinobi_CCTV_06.avif 1009w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/a0b58/WQHD_Shinobi_CCTV_06.webp 230w", "/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/bc10c/WQHD_Shinobi_CCTV_06.webp 460w", "/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/966d8/WQHD_Shinobi_CCTV_06.webp 920w", "/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/288cc/WQHD_Shinobi_CCTV_06.webp 1009w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/81c8e/WQHD_Shinobi_CCTV_06.png 230w", "/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/08a84/WQHD_Shinobi_CCTV_06.png 460w", "/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/c0255/WQHD_Shinobi_CCTV_06.png 920w", "/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/f6f7a/WQHD_Shinobi_CCTV_06.png 1009w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/191cc3e3c2e0b624bb29369ee6b0fad2/c0255/WQHD_Shinobi_CCTV_06.png",
              "alt": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "title": "Adding your INSTAR 2k+ WQHD Camera to Shinobi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      